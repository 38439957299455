import {
  Avatar,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import { useNavigate } from 'react-router-dom';
import { DrawerDialog } from '../../components/commons/DrawerDialog/DrawerDialog';
import { CreateInput } from '../../components/commons/CreateInput/CreateInput';
import { useState } from 'react';
import { useDashboardApi } from '../../hooks/use-dashboard-api';
import { getPlaceHolder } from '../../helpers/list-helper';
import { ShortcutCopy } from '../../components/commons/ShortCutCopy/ShortCutCopy'
import { useAtomUsers } from '../../services/jotai-store-service'
import {User} from '../../data/user';

export const UsersPage = () => {
  const dialog = useDialog();
  const dashboardService = useDashboardApi();
  const [inviteIsLoading, setInviteIsLoading] = useState<boolean>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [fisrtName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [publicName, setPublicName] = useState<string>();

  const [email, setEmail] = useState<string>();
  // const [triggerRefresh, setTriggerRefresh] = useState<boolean>(true);

  const [users, loadingUsers] = useAtomUsers();

  
  const handleUpdate = async(id:string) => {
    // const result = await dashboardService?.updateUser(id, "Mentaport");
  };
  const handleCreateNotImplementedFeature = () => {
    dialog.notify(
      MessageStatus.Info,
      'Feature',
      'This feature will be implemented shortly'
    );
  };

  const handelInvitation = async () => {
    if (!email || !fisrtName || !lastName || !publicName) {
      return;
    }
    try {
      const fullName = fisrtName + ' ' + lastName;
      setInviteIsLoading(true);
      const result = await dashboardService?.inviteUser(email, fullName, publicName);
      if (result?.error) {
        dialog.notify(MessageStatus.Error, 'Error', result?.data.error);
        return;
      }
      if (!result.status) {
        dialog.notify(MessageStatus.Error, 'Error', result?.data.msg);
        return;
      }
      dialog.notify(
        MessageStatus.Success,
        'User Invitation',
        'The invite was sent successfully!'
      );
      onClose();
      // console.log('add user res',result.data.data)
      const user_n = result.data.data;
       navigate(`contracts/${user_n.customerId}/${user_n.email}`);
      // setTriggerRefresh(!triggerRefresh);
      // redirect to user page:
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Error',
        'The invitation process failed!'
      );
    } finally {
      setEmail('');
      setFirstName('');
      setLastName('');
      setPublicName('');
      setInviteIsLoading(false);
    }
  };

  const getManagementButtons = () => {
    return [
      <Button
        marginLeft={10}
        variant="pink"
        size="sm"
        onClick={() => {
          onOpen();
        }}
      >
        Invite User
      </Button>,
    ];
  };

  const renderUserEntry = (user: User) => {
 
    return (
      <Tr key={user.customerId}>
        {/* <Td>
          <Avatar
            boxSize="10"
            name={user?.name || ''}
            // src={user?.picture || ''}
          />
        </Td> */}
        <Td> <ShortcutCopy id={user.customerId} /></Td>
        <Td> <ShortcutCopy id={user.authId} color={'white'} /></Td>
        <Td>{user.publicName}</Td>
        <Td>{user.name}</Td>
        <Td>{user.email}</Td>
        <Td>
          <Button
            onClick={() => {
              navigate(`contracts/${user.customerId}/${user.email}`);
            }}
          >
            Open
          </Button>
        </Td>
        <Td>
          <Button
            variant="pinkSoft"
            isDisabled={true}
            
            onClick={() => {
              handleCreateNotImplementedFeature();
            }}
          >
            Reset
          </Button>
        </Td>
        {/* <Td>
          <Button
            variant="pinkSoft"
            onClick={() => {
              handleUpdate(user.authId);
            }}
          >
            Update
          </Button>
        </Td> */}
      </Tr>
    );
  };

  const renderUsers = () => {
    let userItems: any[] = [];
    if (users?.map) {
      userItems = users.map(renderUserEntry);
    }
    return (
      <TableContainer key="user-list" overflowY={'scroll'}>
        <Table width={'100%'}>
          <Thead>
            <Tr>
              {/* <Th></Th> */}
              <Th>CustomerId</Th>
              <Th>AuthId</Th>
              <Th>Public Name</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Contracts</Th>
              <Th>API</Th>
            </Tr>
          </Thead>
          <Tbody>{userItems}</Tbody>
        </Table>
      </TableContainer>
    );
  };

  const renderSideDrawer = () => {
    return (
      <DrawerDialog
        key="side-drawer"
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        title={'Invite user'}
      >
        <Flex className="UserInviteContainer" gap={5} direction={'column'}>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="Email *"
              value={email ?? ''}
              onChange={value => setEmail(value)}
            />
          </Flex>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="First Name *"
              value={fisrtName ?? ''}
              onChange={value => setFirstName(value)}
            />
          </Flex>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="Last Name *"
              value={lastName ?? ''}
              onChange={value => setLastName(value)}
            />
          </Flex>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="Public User Name *"
              value={publicName ?? ''}
              onChange={value => setPublicName(value)}
            />
          </Flex>
          <Button
            isDisabled={!email || !fisrtName || !lastName}
            variant={'pinkSoft'}
            isLoading={inviteIsLoading}
            onClick={() => handelInvitation()}
          >
            Invite
          </Button>
        </Flex>
      </DrawerDialog>
    );
  };

  return (
    <ManagementPage
      title={'Users'}
      path={'users'}
      isLoading={loadingUsers}
      previousLocation={''}
      placeholder={getPlaceHolder()}
      buttons={getManagementButtons()}
    >
      {renderSideDrawer()}
      {renderUsers()}
    </ManagementPage>
  );
};

