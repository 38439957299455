import { GiMoebiusTriangle } from 'react-icons/gi';
import { HiHome, HiOutlineArrowSmRight, HiOutlineUsers } from 'react-icons/hi';
import { DiGoogleAnalytics } from "react-icons/di";
import { MdAnalytics } from "react-icons/md";
import { FaCubes } from "react-icons/fa";

export const data = {
  companyName: 'Mentaport',
  primaryColor: 'black',
  darkerColor: '#f2f2f2',
  secondaryColor: '#1B1B1B',
  linearGradient: ['white', 'white'],
  '404': '404 Page not found',
  contactUri: 'mailto:support@mentaport.com',
  Navbar: [
    {
      icon: HiHome,
      slug: 'home',
      displayName: 'Home',
    },
    {
      icon: GiMoebiusTriangle,
      slug: 'contracts',
      displayName: 'Contracts',
    },
    // {
    //   icon: HiOutlineArrowSmRight,
    //   slug: 'contracts/mezzanine',
    //   displayName: 'Mezzanine',
    // },
    // {
    //   icon: HiOutlineArrowSmRight,
    //   slug: 'contracts/blockchain',
    //   displayName: 'Blockchain',
    // },
    {
      icon: HiOutlineUsers,
      slug: 'users',
      displayName: 'Users',
    },
    {
      slug: 'contractAnalytics',
      displayName: 'Contract Analytics',
      icon: DiGoogleAnalytics
    },
    {
      icon: MdAnalytics,
      slug: 'certificateAnalytics',
      displayName: 'Certificate Analytics'
    },
    {
      icon: FaCubes,
      slug: 'blockchainAdmin',
      displayName: 'Blockchain Admin',
      style: {
        marginTop: 25,
      }
    },
  ],
  icon: '/images/brand/mentaport-icon.svg',
  smallIcon: '/images/brand/mentaport-small-icon.svg',
};
