import {
  Box,
  Button,
  Flex,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Skeleton,
  Text,
  Link,
} from '@chakra-ui/react';
import './HomePage.scss';
import { useState } from 'react';
import { useAtomApiKeys } from '../../services/jotai-store-service'
import { useNavigate } from 'react-router-dom';
import { LocalIcons } from '../../assets/icons/icons';
import { useAnalytics } from '../../hooks/use-analytics';
import { useAuth0 } from "@auth0/auth0-react";
import { configuration } from '../../../config/configuration';
// just for analytic test-> remove
import { useMentaport } from '../../hooks/use-mentaport';

export const HomePage = () => {
  const { user } = useAuth0();
  const [reveal, setReveal] = useState(false);
  const [apiKeys, isLoading] = useAtomApiKeys();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const certDomain = configuration.certificateAppUserRegistry.baseUrl;
  // just for analytic test-> remove
  const mentaportService = useMentaport();

  if (user) {
    analytics?.identify(user.sub || user.email, {
      email: user.email,
      name: user.name,
      username: user.nickname,
      avatar: user.picture,
    });
  }

  const renderApiKeyBox = () => {
    return (
      <Box className="MentaportCard ApiKeys">
        <Box className="Title">API KEY</Box>
        <Skeleton className="Content" isLoaded={!isLoading}>
          <Box className="Content">
            {!isLoading && apiKeys.length ? (
              <Box className="KeyEntry">
                <InputGroup size="sm">
                  <InputLeftAddon
                    children="Key value"
                    borderRadius={'5px 0 0 5px'}
                  />
                  <Input
                    pr="4.5rem"
                    borderRadius={'0 5px 5px 0'}
                    isReadOnly={true}
                    type={reveal ? 'text' : 'password'}
                    value={apiKeys}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      variant={'yellow'}
                      size={'xs'}
                      onClick={() => {
                        setReveal(!reveal);
                      }}
                    >
                      {reveal ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            ) : (
              <Button
                variant={'pink'}
                size={'xs'}
                onClick={() => {
                  navigate('/create-api-key');
                }}
              >
                Create
              </Button>
            )}
          </Box>
        </Skeleton>
      </Box>
    );
  };


  return (
    <Flex className="Dashboard" flexDirection={'column'} gap={5}>
      <Box className="Placeholder">
        <div className="intro-block">
          <Text fontFamily={'Exo'} as="b" fontSize={'4xl'}>
            Admin Dashabord for Content Certificates
          </Text>
          <h2>
          Our in-house tool for adding and managing user certificate contracts.
          </h2>
         
        </div>
        <div className="code-block">
          {/* <code>npm install @mentaport/core</code> */}
        </div>
      </Box>
      <Box className="Container">
        {renderApiKeyBox()}
        <Box className="MentaportCard DocLinks">
          <Box className="Title">USEFUL LINKS</Box>
          <Box className="dashboard_link_container">
            <Link
              className="dashboard_link"
              href="https://docs.mentaport.xyz"
              isExternal
            >
              <Img cursor={'pointer'} src={LocalIcons.SDK} />
              Docs
            </Link>
            <Link
              className="dashboard_link"
              href={certDomain}
              isExternal
            >
              <Img cursor={'pointer'} src={LocalIcons.Tutorials}></Img>
              Certificate App
            </Link>
            <Link
              className="dashboard_link"
              href="https://github.com/mentaport"
              isExternal
            >
              <Img cursor={'pointer'} src={LocalIcons.GitHub}></Img>
              GitHub
            </Link>
          </Box>
        </Box>
        {/* <a
          className="MentaportCard ShowCase"
          href="https://www.youtube.com/watch?v=BmaK0lM-5JA"
        >
          <Flex
            className="media_button"
            justify={'center'}
            align={'center'}
            bgColor={'#44404090'}
            w={100}
            h={100}
            borderRadius={500}
          >
            <Box
              className="media_button_image"
              backgroundImage={LocalIcons.VideoMark}
              backgroundSize={'contain'}
              backgroundRepeat={'no-repeat'}
              marginLeft={3}
              w={10}
              h={10}
            />
          </Flex>
        </a> */}
      </Box>
    </Flex>
  );
};

// export default DashboardPage;// = withAuthProtection(Page);
