import {
  Badge,
  Button,
  Card,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import './ContractListPage.scss';
import { trackEvent, useAnalytics } from '../../hooks/use-analytics';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  IResults,
  IContract,
  ICertificateContract,
  ContractStatus,
  ContractEnvironment,
} from '@mentaport/supplement';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { getPlaceHolder } from '../../helpers/list-helper';
import { Routes } from '../../data/routes';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useState } from 'react';
import {
  ContractEnvironmentContent,
  ContractListComponent,
} from '../../components/commons/ContractListComponent/ContractListComponent';
import { GiMoebiusTriangle } from 'react-icons/gi';
import { DrawerDialog } from '../../components/commons/DrawerDialog/DrawerDialog';

export const ContractListPage = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const mentaport = useMentaport();
  const dialog = useDialog();
  const [contractAddress, setContractAddress] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activationLoading, setActivationLoading] = useState(false);
  const [lastClickedContract, setLastClickedContract] = useState<ICertificateContract>();

  const [contractsResponse, contractsResponseLoading, setContracts] =
   
    useDataFetcher<IResults<ICertificateContract[]>>({
      serviceCall: () => {
        return mentaport?.getContracts("");
      },
      dependencies: [mentaport],
      conditionForExecution: mentaport !== undefined,
      onError(error) {
        dialog.notify(
          MessageStatus.Error,
          'Contracts',
          'There was an error fetching the contracts.'
        );
        console.error(error);
      },
    });

  // const handleCreateContract = async () => {
  //   if (analytics) {
  //     trackEvent('Navigate to Create a new Contract', analytics);
  //   }
  //   navigate('/contracts/new');
  // };

  const handleActivate = async (contract: ICertificateContract) => {
    setLastClickedContract(contract);
    // if mezzanine:
    if(contract.environment === ContractEnvironment.Mezzanine){
      await handleMezzanineActivate(contract);
      return;
    }
    onOpen();
  };


  const handleMezzanineActivate = async (contract: ICertificateContract) => {
    try {
      if (analytics) {
        trackEvent('Activate Mezzanine Contract Users', analytics);
      }
     // console.log("contract", contract)
      const result = await mentaport?.activateMezzanineContract(contract,contract.customerId);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Mezzanine Contracts',
          'The contract has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Mezzanine Contracts',
          'There was an issue activating the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Mezzanine Contracts',
        'There was an error activating the contract'
      );
    }
  };
  
  const handlePauseContract = async (contract: ICertificateContract) => {
    try {
      if (analytics) {
        trackEvent('Pause Contract', analytics);
      }
      const result = await mentaport?.pauseContract(contract, contract.customerId);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Paused);
        dialog.notify(
          MessageStatus.Success,
          'Contracts',
          'The contract has been paused'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Contracts',
          'There was an issue pausing the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Contracts',
        'There was an error pausing the contract'
      );
    }
  };

  const handleResumeContract = async (contract: ICertificateContract) => {
    try {
      if (analytics) {
        trackEvent('Resume BlockChain Contract', analytics);
      }
      const result = await mentaport?.resumeContract(contract,contract.customerId);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Blockchain Contracts',
          'The contract has been resumed'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Blockchain Contracts',
          'There was an issue resuming the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Blockchain Contracts',
        'There was an error resuming the contract'
      );
    }
  };

  const updateContractStatus =async (
    contract: IContract,
    newStatus: ContractStatus
  ) => {
    if (analytics) {
      trackEvent('Update Contract', analytics);
    }
    if (contractsResponse?.data === undefined) {
      return;
    }
    const contractsData = contractsResponse?.data;
    const index = _.findIndex(
      contractsData,
      x => x.contractId === contract.contractId
    );
    if (index === -1) {
      return;
    }
    // console.log("updateContractStatus",  contractsData[index].customerId)
    // console.log("updateContractStatus", contract.customerId)
    contractsData[index].status = newStatus;
    contractsData[index].customerId = contract.customerId;
    await setContracts({
      status: true,
      statusCode: 205,
      message: 'Contract Activated ',
      data: contractsData,
    });
  };

  const renderSideDrawer = () => {
    return (
      <DrawerDialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        title={'Type of Activation'}
      >
        <Flex direction="column">
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Recommended</Badge>
            <Text fontSize="lg">On Registry</Text>
            <Text fontSize="m">
              Activate directly in Mentaport Infrastructure
            </Text>
            <Button variant="mint" onClick={handleActivateOnRegistry}>
              Activate
            </Button>
          </Card>
          <Flex
            margin="2rem"
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="lg">OR</Text>
          </Flex>
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Advanced</Badge>
            <Text fontSize="lg">On Existing contract</Text>
            <Text fontSize="m">
              Activate contract in a contract you already own in the blockchain
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={GiMoebiusTriangle} color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Contract Address"
                value={contractAddress}
                onChange={(event: any) =>
                  setContractAddress(event.target.value)
                }
              />
            </InputGroup>
            {/* <Button
              variant="mint"
              disabled={contractAddress.length === 0}
              opacity={contractAddress.length === 0 ? 0.5 : 1}
              onClick={handleActivateOnAlreadyDeployedContract}
            >
              Activate
            </Button> */}
          </Card>
        </Flex>
      </DrawerDialog>
    );
  };


  const handleActivateOnRegistry = async () => {
    try {
      setActivationLoading(true);
      if (analytics) {
        trackEvent('Activate BlockChain Contract', analytics);
      }
      if (lastClickedContract === undefined) {
        return;
      }
      onClose();
      const result = await mentaport?.activateCertificateContract(lastClickedContract,"");
      if (result?.status === true) {
        updateContractStatus(lastClickedContract, ContractStatus.Active);
        dialog.notify(
          MessageStatus.Success,
          'Blockchain Contracts',
          'The contract has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Blockchain Contracts',
          'There was an issue activating the contract'
        );
      }
    } catch (error: any) {
      const errorMessage = error?.data?.message ?? "Error in backend."
      dialog.notify(
        MessageStatus.Error,
        'Blockchain Contracts',
        errorMessage
      );
    } finally {
      setActivationLoading(false);
    }
  };

  const getData = (): ContractEnvironmentContent[] => {
    const groups = _.groupBy(contractsResponse?.data ?? [], x => x.environment);
    return [
      {
        contracts: groups[ContractEnvironment.Mainnet] ?? [],
        environmentType: ContractEnvironment.Mainnet,
      },
      {
        contracts: groups[ContractEnvironment.Testnet] ?? [],
        environmentType: ContractEnvironment.Testnet,
      },
      {
        contracts: groups[ContractEnvironment.Mezzanine] ?? [],
        environmentType: ContractEnvironment.Mezzanine,
      },
      {
        contracts: groups[ContractEnvironment.Localhost] ?? [],
        environmentType: ContractEnvironment.Localhost,
      },
     
    ];
  };

  return (
    <Flex flexDirection="column" height={'100%'} width="100%">
      {renderSideDrawer()}
      <ContractListComponent
        managementPageProps={{
          title: 'Contracts',
          buttons: [],
          path: '',
        
          hideBackButton: true,
          isLoading: contractsResponseLoading,
          placeholder: getPlaceHolder(),
          breadcrumbs: [],
          previousLocation: Routes.Home,
        }}
        complexActivationLoading={activationLoading || isOpen}
        environments={getData()}
        onEditContract={(contract: ICertificateContract) => {
          navigate(`/contracts/edit/${contract.contractId}/${contract.customerId}`);
        }}
        onPauseContract={(contract: ICertificateContract): Promise<void> => {
          return handlePauseContract(contract);
        }}
        onResumeContract={(contract: ICertificateContract): Promise<void> => {
          return handleResumeContract(contract);
        }}
        onActivateContract={(contract: ICertificateContract): Promise<void> => {
          return handleActivate(contract);
        }}
        onManageContractRules={(
          contractId: string,
          contractName: string
        ): void => {
          navigate(`/contracts/${contractId}/${contractName}/rules`);
        }}
        onShowContractAnalytics={(
          contractId: string,
          contractName: string
        ): void => {
          navigate(
            `/contracts/${contractId}/${contractName}/analytics`
          );
        }}
      />
    </Flex>
  );
};

